import React, { useEffect, useState } from "react";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import pageleft from "../../../assets/dashboard/pagination-left.png";
import pageright from "../../../assets/dashboard/pagination-right.png";
import "../Dashboard/sub-Pages/index.css";
import ReactPaginate from "react-paginate";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../Layout/Header";
import * as XLSX from "xlsx";
import ExcelMisReport from "./ExcelMisReport";
import { callApi } from '../../../utils/Api';

interface FileData {
    request: any
}
interface DashboardProps {
    setLoading: (loading: boolean) => void;
}
const MisSubReport: React.FC<DashboardProps> = ({ setLoading }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const navigationData = location.state?.request;
    console.log("navigationData ____________", navigationData);
    const [files, setFiles] = useState([]);
    const [limitList, setLimitList] = useState(["5", "10", "15", "20", "All"]);
    const [selectLimit, setSelectLimit] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedlimit, setSelectedlimit] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [dates, setDates] = useState("");
    const [branch, setBranch] = useState("");
    const [currentfilesDetails, setCurrentFilesDetails] = useState<
        { total: any; status: any }[]
    >([]);
    const [totalFileCount, setTotalFileCount] = useState(0);

    useEffect(() => {
        setFiles(navigationData.responseJson);
        setDates(navigationData.date);
        setBranch(navigationData.branch);
        console.log("PendingQuerySubreport Files---", files);
    }, [navigationData]);

    useEffect(() => {
        console.log("updated PendingQuerySubreport Files---", files);
    }, [files, itemsPerPage]);
    useEffect(() => {
        getCurrentFilesDetails(1)
    }, [])

    const getCurrentFilesDetails = (pagecount: any) => {
        setCurrentFilesDetails([]);
        // const { request } = navigationData;
        // console.log("jjjdsugey====================>",request);

        navigationData.page_id = pagecount;
        setLoading(true)
        callApi("POST", "bank_url_mis_report_new.php", navigationData)
            .then((res) => res.data)
            .then((result) => {
                setCurrentFilesDetails(result.responseJson);
                localStorage.setItem("filepagelocalstorage", JSON.stringify(result.data));
                setTotalFileCount(result.count);
                setCurrentPage(prevPage => {
                    const newPage = prevPage;
                    return newPage;
                });
            })
        setLoading(false)
    }


    const handlepageListChange = (event: any) => {
        if (event.target.value == "All") {
            setSelectedlimit(event.target.value);
            setSelectLimit(event.target.value);
            setItemsPerPage(totalFileCount);
        } else {
            setSelectLimit(event.target.value);
            setItemsPerPage(event.target.value);
        }
    };

    const handlePageClickprevious = () => {
        // setCurrentPage(currentPage - 1);
        setCurrentPage(prevPage => {
            const newPage = prevPage - 1;
            getCurrentFilesDetails(newPage);

            return newPage;
        });
        // getCurrentFilesDetails(pageCount);
    };
    const handlePageClicknext = () => {
        // setCurrentPage(currentPage + 1);
        setCurrentPage(prevPage => {
            const newPage = prevPage + 1;
            getCurrentFilesDetails(newPage);

            return newPage;
        });
    };

    const pageCount = Math.ceil(totalFileCount / itemsPerPage);

    const indexOfLastItem = (currentPage) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = currentfilesDetails
    const routeToDashboard = (id: any) => {
        navigate(`/layout/filedash/${id}`);
    };

    const downloadExcel = () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate
            .getDate()
            .toString()
            .padStart(2, "0")}-${(currentDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${currentDate.getFullYear()}`;
        const flattenedData = files.map((file: any, index: any) => {
            const first_set_doc_list = file.firstset_receivedDocList
                .map(
                    (doc: any, docIndex: number) => `${docIndex + 1}. ${doc.mergedValue}`
                )
                .join("\n");
            const query =
                file.query_pending && file.query_pending.length > 0
                    ? file.query_pending
                        .map((query: any, queryIndex: number) => `${query.mergedValue}`)
                        .join("\n")
                    : "Nil";
            const first_query =
                file.first_query && file.first_query.length > 0
                    ? file.first_query
                        .map((query: any, queryIndex: number) => `${query.mergedValue}`)
                        .join("\n")
                    : "Nil";
            return {
                "S.No": file.serial_no,
                "Bank Name": file.organaization_name,
                "Product": file.product_name,
                "Law Hands Branch Name": file.region_name,
                "Bank Branch Name": file.branch_name,
                "LAN No": file.Lan_no,
                "LH File No": file.LH_fileNo,
                "Customer Name": file.customer_name,
                "Property Owner Name": file.property_owner_name,
                "1st Set -Received Doc list": first_set_doc_list,
                "1st set Doc Submitted Date with Time of Login": file?.first_doc_submit_date,
                "1st-Queries": first_query,
                "Draft/preliminary report Released with Query - Date & Time": file.draft_preliminary_report,
                "Current Pending Queries": query,
                "Final Query Doc submission by Bank Date with Time": file.query_final,
                "Final Report released Date with Time": file.Final_report_released_date,
                "Final Status Queries not cleared / Final Report Pending / Report Released": file.Final_status,
            };
        });
        const headers = [
            "S.No",
            "Bank Name",
            "Product",
            "Law Hands Branch Name",
            "Bank Branch Name",
            "LAN No",
            "LH File No",
            "Customer Name",
            "Property Owner Name",
            "1st Set -Received Doc list",
            "1st set Doc Submitted Date with Time of Login",
            "1st-Queries",
            "Draft/preliminary report Released with Query - Date & Time",
            "Current Pending Queries",
            "Final Query Doc submission by Bank Date with Time",
            "Final Report released Date with Time",
            "Final Status Queries not cleared / Final Report Pending / Report Released",
        ];
        const worksheet = XLSX.utils.json_to_sheet(flattenedData, {
            header: headers,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, worksheet, "Files");
        const fileName = `Mis Report ${formattedDate}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    return (
        <>
            <Header title="MIS Report" location="sub" path="misreport" />
            <div className="container mt-5 mobile-view-file-table-mis-report">
                <div className="card">
                    <div className="row table-main-header">
                        <div className="col-md-6 pl-3">
                            {/* <p className="branch-name">{branch} Branch</p> */}
                            {/* <p className='filtered-date'>{dates}</p> */}
                            <p className="filtered-date">
                                {navigationData.from_date}{" "}
                                {navigationData.from_date ? "To" : ""} {navigationData.to_date}
                            </p>
                        </div>
                        <div className="col-md-6 pr-3">
                            {/* <button className="download-btn" onClick={downloadExcel}>
                                <img
                                    className="download-image"
                                    src={excelDownloadIcon}
                                    alt=""
                                />
                                Download
                            </button> */}
                            <ExcelMisReport data={navigationData} />
                        </div>
                    </div>
                    <div className="table-container eod-table report-table pl-3 pr-3">
                        <table style={{ width: "200%" }}>
                            <thead>
                                <tr className="head-row">
                                    <th
                                        style={{ position: "sticky", top: 0, background: "white" }}
                                    >
                                        Sl.No
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        Bank Name
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        Product
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        Law Hands Branch Name
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        Bank Branch Name
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        LAN No
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        LH File No
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        Customer Name
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        Property Owner Name
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        1st Set -Received Doc list
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        1st set Doc Submitted <br />
                                        Date with Time Login
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        1st-Queries
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            background: "white",
                                        }}
                                    >
                                        1st Query Raised Date & Time
                                    </th>
                                    <th
                                        style={{
                                            ...{
                                                position: "sticky",
                                                top: 0,
                                                zIndex: 1,
                                                background: "white",
                                            },
                                        }}
                                    >
                                        Final Query Raised Date & Time
                                    </th>
                                    {/* <th style={{ width: '100px' }} >Draft/preliminary  report <br />Released with Query - Date & Time</th> */}
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 1,
                                            background: "white",
                                        }}
                                    >
                                        Current Pending Queries
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 1,
                                            background: "white",
                                        }}
                                    >
                                        Final Query Doc submission
                                        <br /> by Bank Date with Time
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 1,
                                            background: "white",
                                        }}
                                    >
                                        Final Report released
                                        <br /> Date with Time
                                    </th>
                                    <th
                                        style={{
                                            position: "sticky",
                                            top: 0,
                                            zIndex: 1,
                                            background: "white",
                                        }}
                                    >
                                        Final Status Queries <br /> not cleared / Final Report
                                        Pending
                                        <br /> / Report Released{" "}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems?.length > 0 ? (
                                    currentItems.map((file: any, index: any) => {
                                        return (
                                            <tr
                                                className="body-row"
                                                key={index}
                                                onClick={() => routeToDashboard(file.file_id)}
                                            >
                                                <td>{file.serial_no}</td>
                                                <td>{file.organaization_name}</td>
                                                <td>{file.product_name}</td>
                                                <td>{file.region_name}</td>
                                                <td>{file.branch_name}</td>
                                                <td>{file.Lan_no}</td>
                                                <td>{file.LH_fileNo}</td>
                                                <td>{file.customer_name}</td>
                                                <td>{file.property_owner_name}</td>
                                                <td>
                                                    {file?.firstset_receivedDocList?.length > 0 &&
                                                        file.firstset_receivedDocList.map(
                                                            (doc: any, docindex: any) => (
                                                                <div
                                                                    style={{ textAlign: "center" }}
                                                                    key={docindex}
                                                                >
                                                                    <p>
                                                                        {docindex + 1}. {doc.mergedValue}
                                                                    </p>
                                                                </div>
                                                            )
                                                        )}
                                                </td>
                                                <td>{file?.first_doc_submit_date}</td>
                                                <td>
                                                    {file.first_query && file.first_query.length > 0 ? (
                                                        file.first_query.map((list: any, index: any) => {
                                                            const mergedValueWithLineBreaks =
                                                                list.mergedValue.replace(/\n/g, "\r\n");
                                                            return (
                                                                <p key={index}>{mergedValueWithLineBreaks}</p>
                                                            );
                                                        })
                                                    ) : (
                                                        <p>Nil</p>
                                                    )}
                                                </td>
                                                <td>{file.querie_raised_date ?? 'Nil'}</td>
                                                <td>{file.draft_preliminary_report}</td>
                                                <td>
                                                    {file.query_pending &&
                                                        file.query_pending.length > 0 ? (
                                                        file.query_pending.map((list: any, index: any) => {
                                                            const mergedValueWithLineBreaks =
                                                                list.mergedValue.replace(/\n/g, "\r\n");
                                                            return (
                                                                <p key={index}>{mergedValueWithLineBreaks}</p>
                                                            );
                                                        })
                                                    ) : (
                                                        <p>Nil</p>
                                                    )}
                                                </td>
                                                <td>{file.query_final ? file.query_final : "Nil"}</td>
                                                <td>
                                                    {file.Final_report_released_date
                                                        ? file.Final_report_released_date
                                                        : "Nil"}
                                                </td>
                                                <td>{file.Final_status}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <>
                                        <tr>
                                            <td
                                                colSpan={10}
                                                style={{
                                                    textAlign: "center",
                                                    justifyItems: "center",
                                                    color: "gray",
                                                    fontSize: "20px",
                                                }}
                                            >
                                                No data available
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination-and-info">
                            {/* Pagination */}
                            <div className="pagination">
                                <button onClick={handlePageClickprevious} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === 1}>
                                    <img
                                        src={pageleft}
                                        alt="left arrow"
                                        style={{ marginRight: "5px", width: "7px", height: "12px" }}
                                    />
                                </button>
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px", backgroundColor: "white", margin: "5px 0px 0px 5px", display: 'flex' }}>
                                    {pageCount === 0 ? "0" : currentPage}
                                    <p style={{ padding: "0px 10px 0px 10px" }}>/</p>
                                    {pageCount}
                                </span>
                                <button onClick={handlePageClicknext} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === pageCount}>
                                    <img
                                        src={pageright}
                                        alt="right arrow"
                                        style={{ marginRight: "5px", width: "7px", height: "12px" }}
                                    />
                                </button>
                            </div>
                            <div className='pagination'>
                                <span style={{ marginRight: "10px", marginTop: "15px" }}>Total Count : {totalFileCount}</span>

                            </div>
                            {/* <div className="row-per-page mt-5">
                                <span style={{ marginRight: "10px" }}>Rows per page</span>
                                <select className='select' aria-placeholder='Choose Branch' value={selectLimit} onChange={handlepageListChange} style={{ height: "30px", width: "48px", padding: "0px 2px 0px" }}>
                                    {limitList.map((limitValue, index) => (
                                        <option key={index} value={limitValue}>
                                            {limitValue}
                                        </option>
                                    ))}
                                </select>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default MisSubReport;
