import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import { callApi } from '../../../utils/Api';
import { Spinner } from "react-bootstrap";

const ExcelPendingQuery = ({ data }) => {
  const [currentfilesDetails, setCurrentFilesDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCurrentFilesDetails = () => {
    setCurrentFilesDetails([]);
    setLoading(true);
    callApi("POST", "bank_url_pending_query_file_new_excel.php", data)
      .then((res) => res.data)
      .then((result) => {
        setLoading(false);
        setCurrentFilesDetails(result.data);
        exportExcelFile(result.data);
      })
  }

  const exportExcelFile = async (data) => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Define columns with headers
    worksheet.columns = [
      { header: 'S.No', key: 's_no', width: 10 },
      { header: 'Name', key: 'property_owner_name', width: 30 },
      { header: 'LH File No', key: 'file_name', width: 15 },
      { header: 'Application No', key: 'application_no', width: 30 },
      { header: 'Queries', key: 'query', width: 50 },
      { header: 'Querie Raised Date & Time', key: 'query_raised_dt', width: 30 },
    ];

    // Apply alignment and color to header cells
    worksheet.getRow(1).eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF00' }, // Yellow color
      };
      cell.font = { bold: true, size: 14 };
    });

    const flattenedData = data.map((file, index) => {
      const queries = file.query.map((queryObj, queryIndex) => `${queryIndex + 1}. ${queryObj.query}`).join('\n');
      return {
        s_no: index + 1,
        property_owner_name: file.property_owner_name,
        file_name: file.file_name,
        application_no: file.application_no,
        query: queries,
        query_raised_dt: file.query_raised_dt,
      };
    });

    // Add data rows with custom cell alignment and wrap text
    flattenedData.forEach(item => {
      const row = worksheet.addRow(item);
      row.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      });
    });

    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;

    // Generate Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `Pending Query ${formattedDate}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <button className="download-btn" onClick={getCurrentFilesDetails} disabled={loading}>
        {loading ?
          <Spinner style={{ width: "20px", height: '20px' }} /> :
          <>
            <img className="download-image" src={excelDownloadIcon} alt="Download Excel" />
            Download
          </>
        }
      </button>
    </>
  );
};

export default ExcelPendingQuery;
