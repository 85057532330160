import React, { useEffect, useState } from "react";
import ExcelJS from "exceljs";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import { getFileStatus } from "../../../utils/getFileStatus";
import { callApi } from '../../../utils/Api';
import { Spinner } from "react-bootstrap";

const ExcelEodReport = ({ data }) => {
    const [currentfilesDetails, setCurrentFilesDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    const getCurrentFilesDetails = () => {
        setCurrentFilesDetails([]);
        setLoading(true);
        callApi("POST", "bank_url_process_eod_new_excel.php", data)
            .then((res) => res.data)
            .then((result) => {
                setLoading(false);
                setCurrentFilesDetails(result.data);
                exportExcelFile(result.data);
            })
    }

    const exportExcelFile = async (data) => {
        if (!Array.isArray(data)) {
            console.error("Data is not an array:", data);
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Define columns with headers
        worksheet.columns = [
            { header: 'S.No', key: 's_no', width: 10 },
            { header: 'LH Branch Name', key: 'branch', width: 30 },
            { header: 'LAN No', key: 'app_id', width: 15 },
            { header: 'LH File No', key: 'file_name', width: 15 },
            { header: 'Property OwnerName', key: 'property_owner_name', width: 30 },
            { header: 'Status', key: 'file_status', width: 15 },
        ];

        // Apply alignment and color to header cells
        worksheet.getRow(1).eachCell((cell) => {
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }, // Yellow color
            };
            cell.font = { bold: true, size: 14 };
        });

        const flattenedData = data.map((file, index) => {
            return {
                s_no: file.s_no,
                branch: file.branch,
                app_id: file.app_id,
                file_name: file.file_name,
                property_owner_name: file.property_owner_name,
                file_status: file.file_status,
            };
        });

        // Add data rows with custom cell alignment and wrap text
        flattenedData.forEach(item => {
            const row = worksheet.addRow(item);
            row.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        // Generate Excel file buffer
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Eod Report ${formattedDate}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <button className="download-btn" onClick={getCurrentFilesDetails} disabled={loading}>
                {loading ?
                    <Spinner style={{ width: "20px", height: '20px' }} /> :
                    <>
                        <img className="download-image" src={excelDownloadIcon} alt="Download Excel" />
                        Download
                    </>
                }
            </button>
        </>
    );
};

export default ExcelEodReport;
